<template>
  <v-card id="account-setting-card">

    <!-- tabs -->
    <v-tabs
      v-model="tab"
      show-arrows
      ref="refTab"
    >
      <v-tab
        v-for="tab in tabs"
        :key="tab.icon"
      >
        <v-icon
          size="20"
          class="me-3"
        >
          {{ tab.icon }}
        </v-icon>
        <span>{{ tab.title }}</span>
      </v-tab>
      <v-tab
        dark
        class="ml-auto"
        :to="{ name: 'apps-user-list' }"
      >
        <v-icon
          size="20"
          class="me-3"
        >
          {{ icons.mdiArrowLeft }}
        </v-icon>
        Back to list
      </v-tab>
    </v-tabs>

    <!-- tabs item -->
    <v-tabs-items v-model="tab">
      <v-tab-item>
        <user-edit-info :user-data="userData.info" @update-data="updateData"></user-edit-info>
      </v-tab-item>

      <v-tab-item>
        <user-edit-settings :user-data="userData.settings" @update-data="updateData"></user-edit-settings>
      </v-tab-item>

    </v-tabs-items>
  </v-card>
</template>

<script>
import {
  mdiAccountOutline,
  mdiLockOpenOutline,
  mdiInformationOutline,
  mdiBookmarkOutline,
  mdiBellOutline,
  mdiLocker,
  mdiCalendar,
  mdiArrowLeft
} from '@mdi/js'
import store from '@/store'
import router from '@/router'
import { onUnmounted, ref } from '@vue/composition-api'
import userStoreModule from '../userStoreModule'

import UserEditInfo from './UserEditInfo.vue'
import UserEditSettings from './UserEditSettings.vue'

export default {
  components: {
    UserEditInfo,
    UserEditSettings
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = 'app-user'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    const tab = ref(null)
    const refTab = ref(null)
    const userData = ref({
      info: {},
      settings: {}
    })

    // tabs
    const tabs = [
      { title: 'Info', icon: mdiAccountOutline },
      { title: 'Settings', icon: mdiLocker },
    ]

    store
      .dispatch('app-user/fetchUser', { id: router.currentRoute.params.id })
      .then(response => {

        userData.value.info = {
          username: response.data.user.username,
          email: response.data.user.email,
          first_name: response.data.user.first_name,
          last_name: response.data.user.last_name,
          avatar: 3
        }
        userData.value.settings = {
          date_joined: response.data.user.date_joined,
          last_login: response.data.user.last_login,
          is_active: response.data.user.is_active,
          is_superuser: response.data.user.is_superuser,
          role: response.data.user.is_superuser ? "admin" : "user"
        }

      })
      .catch(error => {
        if (error.response.status === 404) {
          userData.value = {}
        }
      })

    const updateData = (data) => {

      store.dispatch('app-user/updateUser', {id:router.currentRoute.params.id,userData: data}).then(() => {
        store.dispatch("addNotification",{message:"User settings were saved.",type: "success"});
      }).catch((error) => {
        store.dispatch("addNotification",{message:error.data,type: "error"});
      })

    }

    return {
      userData,
      refTab,
      tab,
      tabs,
      updateData,
      icons: {
        mdiAccountOutline,
        mdiLockOpenOutline,
        mdiInformationOutline,
        mdiBookmarkOutline,
        mdiBellOutline,
        mdiLocker,
        mdiCalendar,
        mdiArrowLeft
      },
    }
  },
}
</script>
