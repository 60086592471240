import axios from '@axios'
import themeConfig from '@themeConfig'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchUsers(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get(themeConfig.api_prefix + '/users', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchUser(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(themeConfig.api_prefix + `/users/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchMe(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get(themeConfig.api_prefix + `/me`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    removeUser(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(themeConfig.api_prefix + `/users/${id}/delete`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addUser(ctx, userData) {
      return new Promise((resolve, reject) => {
        axios
          .post(themeConfig.api_prefix + '/users', { user: userData })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateUser(ctx, {id,userData}) {
      return new Promise((resolve, reject) => {
        axios
          .post(themeConfig.api_prefix + `/users/${id}/edit`,  userData )
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateMe(ctx, {userData}) {
      return new Promise((resolve, reject) => {
        axios
          .post(themeConfig.api_prefix + `/me/update`,  userData )
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    }
  },
}
