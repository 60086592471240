<template>
  <v-card
    flat
    class="pa-3 mt-2"
  >
    <v-card-text>
      <h4>Avatar</h4>
    </v-card-text>
    <v-card-text class="d-flex demo-space-x">
      <v-badge
        :value="accountDataLocale.avatar == index ? true : false"
        bordered
        avatar
        overlap
        color="success"
        :icon="icons.mdiCheck"
        v-for="index in 8" :key="index"
      >
        <v-avatar>
          <v-img :src="require('@/assets/images/avatars/'+ index +'.png')" @click="selectAvatar(index)"></v-img>
        </v-avatar>
      </v-badge>

    </v-card-text>

    <v-card-text>
      <v-form class="multi-col-validation mt-6"
        ref="form"
        v-model="valid"
        @submit.prevent="submitForm"
      >
        <v-row>
          <v-col
            md="5"
            cols="12"
          >
            <v-text-field
              v-model="accountDataLocale.username"
              label="Username"
              dense
              outlined
              class="mb-5"
            ></v-text-field>

            <v-text-field
              v-model="accountDataLocale.first_name"
              label="First name"
              dense
              outlined
              class="mb-5"
            ></v-text-field>

            <v-text-field
              v-model="accountDataLocale.last_name"
              label="Last name"
              dense
              outlined
              class="mb-5"
            ></v-text-field>

            <v-text-field
              v-model="accountDataLocale.email"
              label="E-mail"
              dense
              outlined
              :rules="[ v => /.+@.+/.test(v) || 'Invalid Email address' ]"
            ></v-text-field>

          </v-col>

          <v-col cols="12">
            <v-btn
              color="primary"
              class="me-3 mt-4"
              type="submit"
            >
              Save changes
            </v-btn>
            <v-btn
              color="secondary"
              outlined
              class="mt-4"
              type="reset"
              @click.prevent="resetForm"
            >
              Cancel
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import { mdiAlertOutline, mdiCloudUploadOutline, mdiCheck } from '@mdi/js'
import { ref, watch } from '@vue/composition-api'

export default {
  props: {
    userData: {
      type: Object,
      required: true
    },
  },
  setup(props, { emit }) {
    const status = ['Active', 'Inactive', 'Pending', 'Closed']

    const accountDataLocale = ref(JSON.parse(JSON.stringify(props.userData)))

    const resetForm = () => {
      accountDataLocale.value = JSON.parse(JSON.stringify(props.userData))
    }

    const valid = ref(false)
    const form = ref(null)

    const submitForm = () => {
      if (valid.value) {
        emit('update-data',accountDataLocale.value)
      } else {
        form.value.validate()
      }
    }

    const selectAvatar = (index) => {
      accountDataLocale.value.avatar = index
    }

    watch(() => props.userData, (newValue, oldValue) => accountDataLocale.value = newValue);

    return {
      status,
      accountDataLocale,
      resetForm,
      selectAvatar,
      valid,
      form,
      submitForm,
      icons: {
        mdiAlertOutline,
        mdiCloudUploadOutline,
        mdiCheck
      },
    }
  },
}
</script>

<style media="screen">
  .v-badge .v-avatar {
    cursor: pointer;
  }
</style>
