<template>
  <v-card class="user-tab-notification">

    <v-card-text>
      <v-switch
        v-model="accountDataLocale.is_active"
        hide-details
        class="mt-0"
        label="Account is enabled"
        :true-value="1"
        :false-value="0"
      >
      </v-switch>
    </v-card-text>

    <v-card-text>
      Date joined: <strong>{{ accountDataLocale.date_joined }}</strong>
    </v-card-text>
    <v-card-text>
      Last login: <strong>{{ accountDataLocale.last_login || '/' }}</strong>
    </v-card-text>

    <v-card-text>

      <v-row>
        <v-col
          md="3"
          cols="12"
          class="mt-3"
        >
          <v-select
          v-model="accountDataLocale.role"
          label="User Role"
          :items="roleOptions"
          item-text="title"
          item-value="value"
          outlined
          dense
          hide-details="auto"
          class="mb-6"
          @change="resolveSuperuser"
        >
        </v-select>
      </v-col>
      </v-row>
    </v-card-text>

    <v-card-actions class="d-flex">
      <div class="">
        <v-btn
          color="primary"
          class="me-3"
          @click="submitForm"
        >
          save changes
        </v-btn>
        <v-btn
          color="secondary"
          outlined
        >
          Cancel
        </v-btn>
      </div>
      <v-btn
        class="ml-auto"
        color="error"
        right
        @click="deleteAccount"
      >
        Delete account
      </v-btn>
    </v-card-actions>
    <confirm ref="confirm"></confirm>
  </v-card>
</template>

<script>
import { mdiAlertOutline, mdiCloudUploadOutline } from '@mdi/js'
import { ref } from '@vue/composition-api'
import Confirm from '@/components/Confirm.vue'
import store from '@/store'
import router from '@/router'

export default {
  components: {
    Confirm
  },
  props: {
    userData: {
      type: Object,
      required: true
    },
  },
  setup(props, {emit}) {

    const roleOptions = [
      { title: 'Administrator', value: "admin" },
      { title: 'User', value: "user"},
    ]

    const confirm = ref(null)

    const accountDataLocale = ref(JSON.parse(JSON.stringify(props.userData)))

    const deleteAccount = () => {
      confirm.value.open('Delete confirmation', 'Are you sure you want to delete this account?').then((confirm) => {
        if (confirm) {
          store.dispatch('app-user/removeUser', {id:router.currentRoute.params.id}).then(() => {
            store.dispatch("addNotification",{message:"User was removed.",type: "success"});
            router.push({ name: 'apps-user-list' })
          }).catch((error) => {
            store.dispatch("addNotification",{message:"You cannot delete yourself.",type: "error"});
          })
        }
      })
    }

    const submitForm = () => {
      emit('update-data',accountDataLocale.value)
    }

    const resolveSuperuser = (value) => {
      if (value == "user") {
        accountDataLocale.value.is_superuser = 0
      } else {
        accountDataLocale.value.is_superuser = 1
      }
    }

    return {
      accountDataLocale,
      roleOptions,
      deleteAccount,
      confirm,
      submitForm,
      resolveSuperuser,
      icons: {
        mdiAlertOutline,
        mdiCloudUploadOutline,
      },
    }
  },
}
</script>
